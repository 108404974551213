import React, { ReactNode, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountMenu from "./accountMenu";
import { Collapse, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  GetMenuItemTranslation,
  GetSubMenuItemTranslation,
} from "src/component/util/menuAndSubMenuList/menuAndSubMenuList";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type RouteGuardProps = {
  children: ReactNode;
};
interface MenuItem {
  Children: any;
  Url: string;
  MenuID: string;
  Name: string;
}
interface ChildMenuProps {
  UserTypeID: Number;
  MenuID: string;
  Name: string;
  Url: string;
  ImagePath: string;
}
const AuthInnerNavbar = ({ children }: RouteGuardProps) => {
  const { t } = useTranslation("authInnerNavbar");
  const [subMenuOpen, setSubMenuOpen] = useState<{ [key: number]: boolean }>(
    {}
  );
  const sessionData = JSON.parse(
    sessionStorage.getItem("MenuList") || "null"
  ) || { Data: [] };
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSubMenuToggle = (
    menuIndex: number,
    Url: string,
    Child: number
  ) => {
    if (Child <= 0 && Url !== "#") {
      handleMenu(Url);
      return;
    }

    setSubMenuOpen((prevState) => {
      const newState: { [key: number]: boolean } = { ...prevState };
      Object.keys(newState).forEach((key) => {
        const numericKey = parseInt(key);
        if (!isNaN(numericKey) && numericKey !== menuIndex) {
          newState[numericKey] = false;
        }
      });
      newState[menuIndex] = !newState[menuIndex];
      return newState;
    });
  };

  const handleMenu = (menuUrl: string) => {
    navigate(menuUrl);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={open}
        sx={{
          position: "fixed",
          bgcolor: "#fff",
          boxShadow:
            " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              color: "#121922",
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Grid container>
            <Grid item lg={12} sx={{ my: "8px" }}>
              <AccountMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={`logo.png`}
            alt={"Logo"}
            sizes="100%"
            style={{ marginRight: "auto", marginLeft: "80px", width: "160px" }}
          />

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List>
          {sessionData.Data.map((menuItem: MenuItem, index: number) => (
            <React.Fragment key={index}>
              <ListItemButton
                onClick={() =>
                  handleSubMenuToggle(
                    index,
                    menuItem.Url,
                    menuItem.Children.length
                  )
                }
              >
                <ListItemIcon sx={{ minWidth: "34px" }}>
                  <img
                    src={GetMenuItemTranslation(menuItem.Name).icon}
                    alt={`Icon for ${menuItem.Name}`}
                    width="22px"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={GetMenuItemTranslation(menuItem.Name).text}
                  className="listItemTextOverride"
                />

                {menuItem.Children.length > 0 &&
                  (subMenuOpen[index] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {menuItem.Children.length > 0 && (
                <Collapse in={subMenuOpen[index]} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {menuItem.Children.map(
                      (subMenuItem: ChildMenuProps, subIndex: number) => (
                        <ListItemButton
                          key={subIndex}
                          onClick={() => handleMenu(subMenuItem.Url)}
                        >
                          <ListItemIcon sx={{ minWidth: "34px" }}>
                            <img
                              src={
                                GetSubMenuItemTranslation(subMenuItem.Name).icon
                              }
                              alt={`Icon for ${subMenuItem.Name}`}
                              width="20px"
                            />
                          </ListItemIcon>

                          <ListItemText
                            primary={
                              GetSubMenuItemTranslation(subMenuItem.Name).text
                            }
                            className="listItemTextOverride"
                          />
                        </ListItemButton>
                      )
                    )}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          mt: "66px",
          maxHeight: "calc(100vh - 66px)",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AuthInnerNavbar;

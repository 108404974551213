import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux";

const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);
const ProfileScreen = lazy(
  () => import("src/pages/innerPages/profile/profileScreen")
);
const AddUserScreen = lazy(
  () => import("src/pages/innerPages/add_user/addUserScreen")
);
const UserReportScreen = lazy(
  () => import("src/pages/innerPages/reports/user_Report/userReportScreen")
);
const UserTransferScreen = lazy(
  () => import("src/pages/innerPages/userTransfer/userTransferScreen")
);
const BankDetailsScreen = lazy(
  () => import("src/pages/innerPages/bank_details/bankDetailsScreen")
);
const BankDetailsReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/bankDetails_Report/bankDetailsReportScreen"
    )
);
const UserEditScreen = lazy(
  () => import("src/pages/innerPages/reports/user_Report/editScreen")
);
const LedgerReportScreen = lazy(
  () => import("src/pages/innerPages/ledger_Report/ledgerReportScreen")
);

const YouGaveScreen = lazy(
  () => import("src/pages/innerPages/you_Gave/youGaveScreen")
);
const YouGotScreen = lazy(
  () => import("src/pages/innerPages/youGot/youGotScreen")
);

const AddCashBookScreen = lazy(
  () => import("src/pages/innerPages/cashBook_Details/cashBookDetailsScreen")
);
const InEntryScreen = lazy(
  () => import("src/pages/innerPages/add_InCashbook/inEntryScreen")
);
const OutEntryScreen = lazy(
  () => import("src/pages/innerPages/add_OutCashBook/OutEntryCashBook")
);
const CashBookReportScreen = lazy(
  () =>
    import("src/pages/innerPages/reports/cashBook_Report/cashBookReportScreen")
);
const MessageSettingAddScreen = lazy(
  () => import("src/pages/innerPages/message_setting/messageSettingScreen")
);
const SMSSettingAddScreen = lazy(
  () => import("src/pages/innerPages/sms/smsSettingAddScreen")
);
const SmsReportScreen = lazy(
  () => import("src/pages/innerPages/reports/sent_sms_report/smsReportScreen")
);
const AdminDashboardScreen = lazy(
  () =>
    import("src/pages/innerPages/dashborad/AdminDashboard/AdminDashboardScreen")
);

const TransactionReverseScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/CashCollection/TransactionReverse/TransactionReverseScreen"
    )
);

const TransactionAddScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/CashCollection/TrasactionAdd/TrasnactionAddScreen"
    )
);

const TransactionReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/TransactionReport/TransactionReportScreen"
    )
);

const FosDashboardScreen = lazy(
  () => import("src/pages/innerPages/dashborad/FOSDashBoard/FosDashboardScreen")
);

const CustomerDashboardScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/CustomerDashboard/CustomerDashboardScreen"
    )
);

const SuperAdminDashboardScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/dashborad/SuperAdminDashBoard/SuperAdminDashboardScreen"
    )
);

const TransactionApproveReject = lazy(
  () =>
    import(
      "src/pages/innerPages/CashCollection/TransactionApproveReject/TransactionApproveReject"
    )
);

const AddProductScreen = lazy(
  () => import("src/pages/innerPages/ProductMaster/AddProductScreen")
);

const ProductMasterReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/ProductMaster_Report/ProductMasterReportScreen"
    )
);

const ProductDetailsScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/ProductGiveTake/ProductDetails/ProductDetailsScreen"
    )
);

const ProductTakeScreen = lazy(
  () =>
    import("src/pages/innerPages/ProductGiveTake/ProductTake/ProductTakeScreen")
);

const ProductApprovelScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/ProductGiveTake/ProductApproval/ProductApprovelScreen"
    )
);

const ProductDetailReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/ProductDetailReport/ProductDetailReportScreen"
    )
);

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/change_password"
            element={<ChangePasswordAddScreen />}
          />
          <Route
            path="/Profile"
            element={<ProfileScreen IsUserProfileUploaded={false} />}
          />
          <Route path="/Add_User" element={<AddUserScreen />} />
          <Route path="/AdminDashboard" element={<AdminDashboardScreen />} />
          <Route path="/User_Report" element={<UserReportScreen />} />
          <Route path="/User_Transfer" element={<UserTransferScreen />} />
          <Route path="/EditScreen" element={<UserEditScreen />} />
          <Route path="/Add_Bank" element={<BankDetailsScreen />} />
          <Route path="/Bank_Report" element={<BankDetailsReportScreen />} />
          <Route path="/Account_Report" element={<LedgerReportScreen />} />
          {/* <Route path="/Transaction" element={<AddGaveGotScreen />} /> */}
          <Route path="/CreditGrant" element={<YouGaveScreen />} />
          <Route path="/PayBackGrant" element={<YouGotScreen />} />
          {/* <Route
            path="/Transaction_Report"
            element={<YouGaveGotReportScreen />}
          /> */}
          <Route
            path="/Transaction_Report"
            element={<TransactionReportScreen />}
          />
          <Route path="/Add_CashBook" element={<AddCashBookScreen />} />
          <Route
            path="/CashBookIN"
            element={<InEntryScreen CashbookDetailID={0} />}
          />
          <Route
            path="/CashBookOUT"
            element={<OutEntryScreen CashbookDetailID={0} />}
          />
          <Route path="/CashBook_Report" element={<CashBookReportScreen />} />
          <Route
            path="/Message_Setting"
            element={<MessageSettingAddScreen />}
          />
          <Route path="/SMS_Setting" element={<SMSSettingAddScreen />} />
          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
          <Route path="/Sent_Sms_Report" element={<SmsReportScreen />} />
          <Route path="/Transaction_Add" element={<TransactionAddScreen />} />
          <Route
            path="/Transaction_Reverse"
            element={<TransactionReverseScreen />}
          />
          <Route path="/FosDashboard" element={<FosDashboardScreen />} />
          <Route
            path="/CustomerDashBoard"
            element={<CustomerDashboardScreen />}
          />
          <Route
            path="/SuperAdminDashboard"
            element={<SuperAdminDashboardScreen />}
          />
          <Route
            path="/Transaction_Approve_Reject"
            element={<TransactionApproveReject />}
          />
          <Route path="/Add_Product" element={<AddProductScreen />} />
          <Route
            path="/Add_Product_Report"
            element={<ProductMasterReportScreen />}
          />
          <Route path="/Product_Give" element={<ProductDetailsScreen />} />
          <Route path="/Product_Take" element={<ProductTakeScreen />} />
          <Route path="/Product_Approvel" element={<ProductApprovelScreen />} />
          <Route
            path="/Product_Report"
            element={<ProductDetailReportScreen />}
          />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
